/* global axios */
import ApiClient from '../ApiClient';

class GupshupClient extends ApiClient {
  constructor() {
    super('channels/gupshup_channel', { accountScoped: true });
  }
}

export default new GupshupClient();
