/* global axios */
import ApiClient from '../ApiClient';

const requestURL = '/agendor_chat/entities';
class EntityAPI extends ApiClient{
  getExternalEntityWithParams({ accountId, phoneNumber }) {
    return axios.get(`${requestURL}/${accountId}`, {
      params: { phone_number: phoneNumber }
    });
  }

  postIntegrationConversation({ accountId, phoneNumber, contactId, name, inboxId, conversationId }) {
    return axios.post(requestURL, {
      params: {
        account_id: accountId,
        phone_number: phoneNumber,
        contact_id: contactId,
        name: name,
        inbox_id: inboxId,
        conversation_id: conversationId
      }
    });
  }
}

export default new EntityAPI();
