<script>
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { useUISettings } from 'dashboard/composables/useUISettings';

export default {
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
  setup() {
    const { uiSettings } = useUISettings();

    return {
      uiSettings,
    };
  },
  computed: {
    isSidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return showSecondarySidebar;
    },
    isRightOrLeftIcon() {
      return this.isSidebarOpen ? 'arrow-chevron-left' : 'arrow-chevron-right';
    },
  },
  methods: {
    onMenuItemClick() {
      this.$emitter.emit(BUS_EVENTS.TOGGLE_SIDEMENU);
    },
  },
};
</script>

<template>
  <woot-button
    :size="size"
    class="-ml-3 text-black-900 dark:text-slate-300"
    :icon="isRightOrLeftIcon"
    @click="onMenuItemClick"
  />
</template>
